<template>
	<div class="lc-operate-set el-content">
		<com-power-form :form-data="[
			{label:'背景图片',type:'image',value:'',key:'land_custom_bg',dataType:'string'},
		]"></com-power-form>
	</div>
</template>
<script>
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		comPowerForm
	}
}
</script>
<style lang="scss">
</style>
